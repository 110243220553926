import Todo from './component/todo/todo';

function App() {
  return (
    <>
    <Todo/>
    </>
  );
}

export default App;
